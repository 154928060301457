import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setApiKey} from "../../../../modules/actions/setApiKey";

const ApiKey = () => {
    const apikeyRef = useRef("");
    const apikey = useSelector(state => state.apikey);
    const dispatch = useDispatch();

    const saveApiKey = () => {
        dispatch(setApiKey(apikeyRef.current.value))
    }

    return (
        <div className="setting apikey">
            <div className="edit-box">
                <div className="label">
                    api Key <i className="ri-information-2-line">
                    <div className="key-tooltip">openAI key 또는 인증된 키를 발급받아 사용해야합니다.</div>
                </i>
                </div>
                <div className="key-text">
                    <textarea ref={apikeyRef} placeholder="api key를 등록해주세요." defaultValue={apikey}/>
                </div>
            </div>
            <div className="submit-box">
                <button onClick={() => saveApiKey()}>SAVE</button>
            </div>
        </div>
    )
}

export default ApiKey;