import React from 'react';
import Profile from "../../molecules/setting/Profile";
import ApiKey from "../../molecules/setting/ApiKey";

const Setting = () => {
    return (
        <div className="setting">
            <Profile/>
            <ApiKey/>
        </div>
    )
}

export default Setting;